import Vue from 'vue'

const Kakao = window.Kakao
const state = () => ({
  token: Kakao.Auth.getAccessToken(),
  refreshToken: localStorage.getItem('refreshToken') || '',
  kakaoLoginModalActive: false,
})
const getters = {
  isLogin: state => state.token !== null && state.token.length > 0,
}
const mutations = {
  showKakaoLoginModal(state) {
    state.kakaoLoginModalActive = true
  },
  hideKakaoLoginModal(state) {
    state.kakaoLoginModalActive = false
  },
  setToken(state, token) {
    Kakao.Auth.setAccessToken(token)
    state.token = token
  },
}
const actions = {
  requestKakaoLogin({ commit }) {
    Kakao.Auth.login({
      success: authObj => {
        commit('setToken', authObj.access_token)
        localStorage.setItem('refreshToken', authObj.refresh_token)
        commit('hideKakaoLoginModal')
      },
      fail: err => Vue.prototype.$showErrorMessage(err),
    })
  },
  requestLogoutKakao({ commit }) {
    Kakao.Auth.logout(() => {
      localStorage.removeItem('refreshToken')
      commit('setToken', null)
      Vue.prototype.$showMessage('logout success')
    })
  },
  requestUnlinkKakao({ commit }) {
    Kakao.API.request({
      url: '/v1/user/unlink',
      success: res => {
        commit('setToken', null)
        localStorage.removeItem('refreshToken')
        Vue.prototype.$showMessage('success: ' + JSON.stringify(res))
      },
      fail: err => {
        Vue.prototype.$showErrorMessage('fail: ' + JSON.stringify(err))
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
