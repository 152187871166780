const LOADING_TIME = process.env.VUE_APP_LOADING_TIME

const state = () => ({
  loadingCheckCount: 0,
  loadingTimeout: null,
})
const getters = {
  isLoading(state) {
    return state.loadingCheckCount > 0
  },
}
const mutations = {
  showLoading(state) {
    state.loadingCheckCount = state.loadingCheckCount + 1
    if (state.loadingTimeout) {
      clearTimeout(state.loadingTimeout)
    }
    state.loadingTimeout = setTimeout(() => {
      state.loadingTimeout = null
      state.loadingCheckCount = 0
    }, LOADING_TIME)
  },
  hideLoading(state) {
    state.loadingCheckCount = state.loadingCheckCount - 1
    if (state.loadingCheckCount <= 0) {
      clearTimeout(state.loadingTimeout)
      state.loadingTimeout = null
      state.loadingCheckCount = 0
    }
  },
}
const actions = {
  showLoading({ commit }) {
    commit('showLoading')
  },
  hideLoading({ commit }) {
    commit('hideLoading')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
