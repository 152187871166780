<template>
  <div class="media-overlay" v-if="showPlayer" @click.stop="close">
    <div class="media-layer">
      <Media class="media" :type="type" :link="link" />
      <div class="close">
        <div class="close-button" @click.stop="close" />
      </div>
    </div>
  </div>
</template>

<script>
import Media from '@/components/Media.vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    Media,
  },
  computed: {
    ...mapState('media', ['type', 'link']),
    ...mapGetters('media', ['showPlayer']),
  },
  methods: {
    ...mapMutations('media', ['setMediaType', 'setMediaLink']),
    close() {
      this.setMediaType(null)
      this.setMediaLink(null)
    },
  },
}
</script>

<style lang="scss" scoped>
.media-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .media-layer {
    position: relative;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    .media {
      width: 100%;
      height: 100%;
    }
    .close {
      position: absolute;
      top: -30px;
      right: -20px;
      .close-button {
        position: relative;
        border-radius: 50%;
        padding: 0.5em;
        width: 30px;
        height: 30px;
        border: 2px solid white;
        color: white;
        cursor: pointer;
      }
      .close-button:hover {
        border: 2px solid white;
        background-color: $t-primary-600;
        color: #ffffff;
      }

      .close-button::before {
        content: ' ';
        position: absolute;
        display: block;
        background-color: white;
        width: 2px;
        left: 12px;
        top: 5px;
        bottom: 5px;
        transform: rotate(45deg);
      }
      .close-button::after {
        content: ' ';
        position: absolute;
        display: block;
        background-color: white;
        height: 2px;
        top: 12px;
        left: 5px;
        right: 5px;
        transform: rotate(45deg);
      }
    }
  }
}
</style>
