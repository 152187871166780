// https://github.com/axios/axios
// https://github.com/imcvampire/vue-axios

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.prototype.$axios = axios

// axios.defaults.timeout = process.env.VUE_APP_LOADING_TIME
const baseURL = process.env.NODE_ENV === 'production' ? 'https://votes-api.typecast.ai' : 'https://votes-api.icepeak.ai'
axios.defaults.baseURL = baseURL

function axiosErrorHandler(error) {
  Vue.prototype.$hideLoading()
  return Promise.reject(error.response)
}

axios.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return axiosErrorHandler(error)
  },
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return axiosErrorHandler(error)
  },
)

Vue.use(VueAxios, axios)
