const URL = process.env.NODE_ENV === 'production' ? 'https://votes.typecast.ai' : 'http://votes.icepeak.ai'
const state = () => ({
  type: null,
  link: null,
  shareLink: '',
})
const getters = {
  showPlayer: state => state.link?.length > 0,
}
const mutations = {
  setMediaType(state, type) {
    state.type = type
  },
  setMediaLink(state, link) {
    state.link = link
  },
  setShareLink(state, id) {
    if (id) {
      state.shareLink = `${URL}/#/mypage?id=${id}`
    } else {
      state.shareLink = ''
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
