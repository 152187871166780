<template>
  <div v-if="kakaoLoginModalActive" class="modal-overlay" @click.self="close()">
    <div class="modal-card">
      <div class="modal-header">
        <div>투표하기</div>
        <a-icon type="close" style="cursor: pointer" @click.stop="close()"></a-icon>
      </div>
      <div class="modal-contents">
        <p>
          투표에 참여하기 위해서는 카카오톡 로그인이 필요합니다.
          <br />
          로그인 이후 투표를 다시 해주세요
        </p>

        <a href="#" @click.stop="requestKakaoLogin">
          <img class="mt20" src="//k.kakaocdn.net/14/dn/btqCn0WEmI3/nijroPfbpCa4at5EIsjyf0/o.jpg" width="200" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      active: false,
    }
  },
  computed: {
    ...mapState('auth', ['kakaoLoginModalActive']),
  },
  methods: {
    ...mapMutations('auth', ['hideKakaoLoginModal']),
    ...mapActions('auth', ['requestKakaoLogin']),
    close() {
      this.hideKakaoLoginModal()
      if (this.$route.path === '/login') {
        this.$router.push({ name: 'Home' })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
