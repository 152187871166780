<template>
  <div v-if="isLoading" class="loading">
    <a-spin size="large" tip="Loading..." />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({ isLoading: 'utils/isLoading' }),
  },
}
</script>
<style lang="scss" scoped>
.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10000;
}
</style>
