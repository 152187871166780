import Vue from 'vue'
import store from '@/store'
import moment from 'moment'

const EventBus = new Vue()

const MyPlugin = function (Vue) {
  Vue.prototype.$showLoading = () => {
    store.dispatch('utils/showLoading')
  }
  Vue.prototype.$hideLoading = () => {
    store.dispatch('utils/hideLoading')
  }
  Vue.prototype.$showMessage = message => {
    Vue.prototype.$message.info(message)
  }
  Vue.prototype.$showErrorMessage = error => {
    console.log('#@# error', error)
    if (!error) return
    if (error.status === 500) {
      Vue.prototype.$message.error('500: INTERNAL SERVER ERROR')
      return
    }

    const UnauthorizedCode = [401]
    if (UnauthorizedCode.includes(error.status)) {
      store.dispatch('auth/requestLogoutKakao')
    }
    const isApiError = error.status && error.data
    if (isApiError) {
      // Vue.prototype.$message.error(`${error.status}:  ${error.data.error.error_code} / ${error.data.error.msg}`)
      Vue.prototype.$message.error(error.data.error?.msg)
    } else {
      Vue.prototype.$message.error(JSON.stringify(error))
    }
  }

  Vue.prototype.$moneyFormat = number => {
    const str = number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return str.slice(0, str.length - 3)
  }

  Vue.prototype.$dateToString = dateTime => {
    if (dateTime) return moment(dateTime * 1000).format('YYYY-MM-DD HH:mm:ss')
    else return ''
  }

  Vue.prototype.$eventBus = EventBus
}

Vue.use(MyPlugin)
