<template>
  <article>
    <div v-if="isYoutube" class="media-wrapper">
      <iframe :src="link" frameborder="0" allowfullscreen class="youtube" />
    </div>
    <div v-else>
      <div v-if="loaded" v-html="getTiktokEmbaddedHtml(link)"></div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'youtube', // 'youtube' or 'tiktok'
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return { loaded: false }
  },
  computed: {
    isYoutube() {
      return this.type === 'youtube'
    },
  },
  created() {
    if (!this.isYoutube) {
      this.loadScript('https://www.tiktok.com/embed.js').then(status => {
        this.loaded = status === 'loaded'
      })
    }
  },
  methods: {
    getTiktokEmbaddedHtml(src) {
      return src.replace(
        'max-width: 605px;min-width: 325px;',
        'background-color: white; max-width: 325px;min-width: 325px; width: 200px; border-radius: 8px;',
      )
    },
    loadScript(url) {
      // https://stackoverflow.com/questions/66490218/problem-embedding-tiktok-video-into-angular-7
      return new Promise((resolve, reject) => {
        const tiktokScript = document.getElementById('tiktok-script')
        if (document.getElementById('tiktok-script')) {
          tiktokScript.remove()
        }

        const script = document.createElement('script')
        script.async = true
        script.src = url
        script.setAttribute('id', 'tiktok-script')

        script.onload = () => {
          resolve('loaded')
        }
        script.onerror = () => {
          reject('error')
        }

        document.head.appendChild(script)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.media-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
  .youtube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
