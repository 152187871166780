<template>
  <a-layout id="app">
    <Header />
    <div class="contents-wrapper">
      <router-view />
    </div>
    <Loading />
    <MediaOverlay />
    <KakaoLoginModal />
    <ShareLinkModal />
  </a-layout>
</template>
<script>
import Header from '@/components/Header'
import Loading from '@/components/common/Loading'
import KakaoLoginModal from '@/components/modal/KakaoLoginModal'
import ShareLinkModal from '@/components/modal/ShareLinkModal'
import MediaOverlay from '@/components/MediaOverlay'

export default {
  name: 'app',
  components: {
    Header,
    Loading,
    ShareLinkModal,
    KakaoLoginModal,
    MediaOverlay,
  },
}
</script>
<style lang="scss">
#app {
  position: relative;
  height: 100vh;
  overflow: auto;
}
</style>
