<template>
  <div v-if="active" class="modal-overlay" @click.self="hideModal()">
    <div class="modal-card">
      <div class="modal-header">
        <div>링크보기</div>
        <a-icon type="close" style="cursor: pointer" @click.stop="hideModal()"></a-icon>
      </div>
      <div class="modal-contents">
        <p>투표할 수 있는 링크를 복사해서</p>
        <p>주변에 널리 알려주세요.</p>

        <div class="share-link">
          <div class="share-link-url">{{ shareLink }}</div>
          <a-button class="ml5" @click.stop="onShareClick">
            <a-icon type="copy" />
            복사
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('media', ['shareLink']),
    active() {
      return this.shareLink.length > 0
    },
  },

  methods: {
    ...mapMutations('media', ['setShareLink']),

    hideModal() {
      this.setShareLink(null)
    },
    onShareClick() {
      this.$copyText(this.shareLink).then(
        () => this.$toast.info('링크복사 완료', { position: 'top' }),
        error => this.$showErrorMessage(error),
      )
      this.hideModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.share-link {
  display: flex;
  align-items: center;
  margin-top: 20px;
  &-url {
    border-radius: 4px;
    background: $t-primary-300;
    color: $t-primary-600;
    padding: 5px 10px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: auto;
  }
}
</style>
