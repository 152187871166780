import Vue from 'vue'
import Vuex from 'vuex'
import utils from './modules/utils'
import auth from './modules/auth'
import media from './modules/media'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { utils, auth, media },
})
