<template>
  <head class="header">
    <img
      class="logo"
      src="@/assets/images/typecast-awards.svg"
      alt="typecast awards 2021 사전투표"
      @click.stop="onHeaderClick"
    />
  </head>
</template>
<script>
export default {
  methods: {
    onHeaderClick() {
      this.$router.push({ name: 'Home' })
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  height: 60px;
  z-index: 10;
  @include tablet {
    height: 80px;
  }
  box-shadow: 0 0 4px $t-grey-700;
  .logo {
    width: 40%;
    min-width: 250px;
    max-width: 300px;
    cursor: pointer;
    @include tablet {
      width: 60%;
    }
  }
}
</style>
